import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Star from "../assets/star1.svg";

function CurrentlyListening() {
  const [artists, setArtists] = useState("");
  const [info, setInfo] = useState({});
  useEffect(() => {
    fetch(
      "https://xgfqxcw902.execute-api.eu-north-1.amazonaws.com/test/getSong"
    )
      .then((res) => res.json())
      .then((data) => {
        setInfo(data);
        let artistNames = "";

        if (data.artists.length > 1) {
          let length = data.artists.length;
          data.artists.forEach((element, index) => {
            if (index === 0) artistNames = element.name;
            if (index < length - 1) artistNames += ", " + element.name;
            else artistNames += "& " + element.name;
          });
        } else {
          artistNames = data.artists[0].name;
        }

        setArtists(artistNames);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  if (
    !window.navigator.userAgent.includes("Windows") &&
    !window.navigator.userAgent.includes("Macintosh")
  ) {
    return (
      <div className="bg-[#16181A] w-screen text-[#EAE5DB] h-screen overflow-hidden scrollbar-hide font-offBitDotBold selection:bg-[#070707] selection:text-[#fbfbff]">
        <div className="bg"></div>
        <div className="text-[20px] px-[20px] font-offBitDotNormal flex justify-between mt-4">
          <Link to="/home" className="hover:underline text-white ">
            home
          </Link>
          <p className="text-[#39afe5]">. . . currently listening</p>
        </div>
        <div className="h-full flex w-full justify-center items-center">
          {!info.thumbnails && (
            <div>
              <p className="text-[#39afe5] text-[20px]">loading....</p>
            </div>
          )}
          {info.thumbnails && (
            <div className=" inset-0 flex items-center justify-center flex-col">
              <img
                className="w-[300px] h-[300px] object-cover rounded-lg border-none"
                src={info.thumbnails[0].url.replace("w60-h60", "w300-h300")}
                alt=""
              />
              <h1 className="flex items-baseline mt-[50px]">
                <span className="font-TTExtraBold text-3xl transition-all m-0 p-0 ">
                  {info.title.replace(/\s+/g, " ")}
                </span>
              </h1>
              <h1 className="p-2 font-TTNormal text-sm transition-all margin-0 text-center">
                {artists}
              </h1>
              {info.inLibrary && (
                <span className="m-0 p-0 flex items-center cursor-pointer mb-[100px]">
                  <img className="w-3 h-3 ml-3 mb-1 mr-2" src={Star} alt="" />
                  <span className="text-sm text-[#7f7f7f] ">i recommend!</span>
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="bg-[#16181A] w-screen text-[#EAE5DB] h-screen overflow-hidden scrollbar-hide font-offBitDotBold selection:bg-[#070707] selection:text-[#fbfbff]">
      <div className="bg"></div>
      <div className="h-full flex w-full justify-center items-center">
        {!info.thumbnails && (
          <div>
            <p className="text-[#39afe5] text-[50px]">loading....</p>
          </div>
        )}
        {info.thumbnails && (
          <>
            <div className=" inset-0 flex items-center justify-center pr-[100px]">
              <img
                className="w-[512px] h-[512px] object-cover rounded-2xl"
                src={info.thumbnails[0].url.replace("w60-h60", "w512-h512")}
                alt=""
              />
            </div>

            <div className="relative w-[500px]">
              <h1 className="flex items-baseline">
                <span className="font-TTExtraBold text-7xl transition-all m-0 p-0 ">
                  {info.title.replace(/\s+/g, " ")}
                </span>
                {info.inLibrary && (
                  <span className="m-0 p-0 flex items-center imgSrc cursor-pointer">
                    <img className="w-6 h-6 ml-3 mb-1 mr-2" src={Star} alt="" />
                    <span className="text-xl text-[#7f7f7f] textSrc">
                      i recommend!
                    </span>
                  </span>
                )}
              </h1>
              <h1 className="p-2 font-TTNormal text-lg transition-all margin-0">
                {artists}
              </h1>
            </div>
          </>
        )}
      </div>
      <div className="sticky bottom-[30px] text-[25px] px-[50px] font-offBitDotNormal flex justify-between">
        <Link to="/home" className="hover:underline text-white ">
          home
        </Link>
        <p className="text-[#39afe5]">. . . currently listening</p>
      </div>
    </div>
  );
}

export default CurrentlyListening;
