import React, { useState } from "react";
import { Link } from "react-router-dom";

function Test() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const handleMouseMove = (event) => {
    const mouseX = event.clientX;
    const mouseY = event.clientY;
    setMousePosition({ x: mouseX, y: mouseY });
  };
  const minWeight = 100;
  const maxWeight = 900;
  const width = window.innerWidth;
  const fontWeight =
    Math.round((mousePosition.x / width) * (maxWeight - minWeight)) + minWeight;
  const minWidth = 50;
  const maxWidth = 150;
  const height = window.innerHeight;
  const fontWidth =
    Math.round((mousePosition.y / height) * (maxWidth - minWidth)) + minWidth;

  const fontVariationSettings = `'wght' ${fontWeight}, 'wdth' ${fontWidth}, 'obli' 7`;
  return (
    <div
      onMouseMove={handleMouseMove}
      className="bg-black w-screen h-screen overflow-hidden scrollbar-hide"
      style={{
        backgroundImage: "url('/car.jpg')",
        backgroundSize: "cover",
        loading: "lazy",
        backgroundPosition: "center",
        zIndex: 0,
      }}
    >
      <div className="h-full flex items-center justify-center selection:bg-[#070707] selection:text-[#fbfbff] z-100 ">
        <div className="bg"></div>
        <div
          className={`z-0 w-[800px] h-[400px] border-white border-2 bg-black overflow-clip`}
        >
          <div className="h-full w-full bg-gradient-to-r from-[#6366f100] via-purple-500 to-[#ec489900] blur-[100px]"></div>
        </div>
        <p
          className=" absolute font-BaseNeue text-center text-white text-[100px] tracking-[0px] z-100"
          style={{
            fontVariationSettings,
          }}
        >
          test page
          <span className="text-[#ceff1a]">!</span>
        </p>
      </div>
      <div className="sticky bottom-[30px] px-[50px]">
        <Link
          to="/home"
          className="hover:underline text-[25px] text-white font-offBitDotNormal"
        >
          home
        </Link>
      </div>
    </div>
  );
}

export default Test;
