import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function TimerPage() {
  const [dnt, setDnt] = useState({
    date: new Date().toISOString().split("T")[0],
    time: "23:30:00",
  });
  const navigateTo = useNavigate();
  
  return (
    <div className="bg-black w-screen h-screen overflow-hidden scrollbar-hide">
      <div className="bg"></div>
      <div className="w-screen h-screen flex justify-center">
        <div className=" w-[50vw] h-[60vh] self-center rounded-md shadow-[0_12px_15px_rgba(8,_112,_184,_0.7)] hover:shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)] transition-all border-[#202020] border-2 flex flex-col p-2 justify-center gap-6">
          <h3 className="self-center font-offBitDotNormal text-[20px] text-[#be0aff] tracking-widest pr-10">
            timer
          </h3>
          <div className="self-center  w-[15vw] flex justify-between">
            <p className="text-[14px] font-offBitDotNormal text-white tracking-widest underline">
              select date:
            </p>
            <input
              type="date"
              defaultValue={dnt.date}
              onChange={(e) => setDnt({ ...dnt, date: e.target.value })}
              className="text-[14px] font-offBitDotNormal text-white tracking-widest h-max bg-transparent"
            />
          </div>
          <div className="self-center  w-[15vw] flex justify-between">
            <p className="text-[14px] elf-center font-offBitDotNormal text-white tracking-widest underline">
              select time:
            </p>
            <input
              type="time"
              defaultValue={dnt.time}
              onChange={(e) => setDnt({ ...dnt, time: e.target.value })}
              className="text-[14px] elf-center font-offBitDotNormal text-white tracking-widest h-max bg-transparent"
            />
          </div>
          <button
            className="self-center bg-[#be0aff] text-white font-offBitDotNormal text-[14px] tracking-widest py-1 px-2 rounded-md mr-10 mt-5"
            onClick={() => {
              navigateTo(`/countdownto/${dnt.date}T${dnt.time}`);
            }}
          >
            set timer
          </button>
        </div>
      </div>
      <div className="absolute bottom-0 p-[30px] flex justify-between">
        <Link
          to="/home"
          className="hover:underline text-[25px] text-white font-offBitDotNormal"
        >
          home
        </Link>
      </div>
    </div>
  );
}

export default TimerPage;
