import { Doughnut } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend);

function Calc() {
  const [inputValue, setInputValue] = useState({
    principal: 0,
    years: 0,
    interest_rate: 0,
    monthly_recurring_amount: 0,
  });

  const [formattedInputs, setFormattedInputs] = useState({
    principal: "",
    monthly_recurring_amount: "",
  });

  const [res, setRes] = useState({
    total_invested: 0,
    total_interest: 0,
    total_amount: 0,
  });

  const chartData = {
    labels: ["Principal", "Monthly Investments", "Interest Earned"],
    datasets: [
      {
        data: [
          inputValue.principal,
          inputValue.monthly_recurring_amount * inputValue.years * 12,
          res.total_interest,
        ],
        backgroundColor: [
          "rgb(54, 162, 235)", // Blue for principal
          "rgb(75, 192, 192)", // Teal for monthly investments
          "rgb(16, 185, 129)", // Emerald for interest (matching your UI)
        ],
        borderColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(16, 185, 129, 0.2)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          font: {
            family: "offBitDotNormal",
            size: 12,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || "";
            if (label) {
              label += ": ";
            }
            let value = context.raw;
            if (value !== null) {
              label +=
                outputFormat +
                value.toLocaleString(outputFormat === "₹" ? "en-IN" : "en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
            }
            return label;
          },
        },
      },
    },
    cutout: "60%",
    responsive: true,
    maintainAspectRatio: true,
  };

  const [outputFormat, setOutputFormat] = useState("$");
  const [copyMessage, setCopyMessage] = useState("");

  const formatIndianNumber = (num) => {
    return new Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(num);
  };

  const formatNumberInput = (value, isIndianFormat) => {
    const numericValue = value.replace(/[^\d.]/g, "");

    if (numericValue === "") return "";

    const number = parseFloat(numericValue);
    if (isNaN(number)) return "";

    if (isIndianFormat) {
      return new Intl.NumberFormat("en-IN", {
        maximumFractionDigits: 0,
      }).format(number);
    } else {
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
      }).format(number);
    }
  };

  const handleNumberInput = (e, field) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^\d.]/g, "");

    setInputValue({
      ...inputValue,
      [field]: Number(numericValue),
    });

    setFormattedInputs({
      ...formattedInputs,
      [field]: value,
    });
  };

  const copyToClipboard = async (value, label) => {
    try {
      if (isNaN(value)) value = 0;
      await navigator.clipboard.writeText(value.toString());
      setCopyMessage(`${label} copied!`);
      setTimeout(() => setCopyMessage(""), 2000); // Clear message after 2 seconds
    } catch (err) {
      console.error("Failed to copy:", err);
      setCopyMessage("Failed to copy");
    }
  };

  useEffect(() => {
    setFormattedInputs({
      principal: inputValue.principal
        ? formatNumberInput(
            inputValue.principal.toString(),
            outputFormat === "₹"
          )
        : "",
      monthly_recurring_amount: inputValue.monthly_recurring_amount
        ? formatNumberInput(
            inputValue.monthly_recurring_amount.toString(),
            outputFormat === "₹"
          )
        : "",
    });
  }, [inputValue.monthly_recurring_amount, inputValue.principal, outputFormat]);

  useEffect(() => {
    const total_invested =
      Number(inputValue.principal) +
      Number(inputValue.monthly_recurring_amount) * inputValue.years * 12;

    const monthlyRate = inputValue.interest_rate / 100 / 12;
    const totalMonths = inputValue.years * 12;

    const principalFV =
      inputValue.principal * Math.pow(1 + monthlyRate, totalMonths);

    const recurringFV =
      inputValue.monthly_recurring_amount *
      ((Math.pow(1 + monthlyRate, totalMonths) - 1) / monthlyRate);

    const total_amount = principalFV + recurringFV;
    const total_interest = total_amount - total_invested;

    setRes({
      total_invested: Math.round((total_invested + Number.EPSILON) * 100) / 100,
      total_interest: Math.round((total_interest + Number.EPSILON) * 100) / 100,
      total_amount: Math.round((total_amount + Number.EPSILON) * 100) / 100,
    });
  }, [inputValue]);

  return (
    <div className="bg-[#eDeBe8] w-screen h-screen overflow-hidden scrollbar-hide flex align-middle self-center justify-center flex-col">
      <div className="bg"></div>
      <div className="flex gap-1 flex-col self-center align-middle justify-center place-self-center w-[90vw] md:w-[40vw] h-[80vh] md:h-[90vh]">
        <h1 className="text-center font-offBitDotBold text-[28px] text-nowrap">
          <button
            onClick={() => {
              setOutputFormat(outputFormat === "$" ? "₹" : "$");
            }}
          >
            <span className="font-offBitDotBold tracking-wider text-[28px] text-emerald-600">
              {outputFormat.repeat(5)}
            </span>
          </button>{" "}
          Calculator{" "}
          <button
            onClick={() => {
              setOutputFormat(outputFormat === "$" ? "₹" : "$");
            }}
          >
            <span className="font-offBitDotBold tracking-wider text-[28px] text-emerald-600">
              {outputFormat.repeat(5)}
            </span>
          </button>
        </h1>
        <div
          id="calc_form"
          className="flex gap-1 flex-col self-center align-middle justify-center place-self-center w-[80vw] md:w-[40vw]"
        >
          <label
            htmlFor="principal"
            className="text-[16px] font-offBitDotNormal tracking-wider"
          >
            Initial amount ({outputFormat})
          </label>
          <input
            type="text"
            id="principal"
            className="text-[16px] font-offBitDotNormal tracking-widest p-1"
            value={formattedInputs.principal}
            placeholder="0"
            onChange={(e) => handleNumberInput(e, "principal")}
          />
          <label
            htmlFor="interest_rate"
            className="text-[16px] font-offBitDotNormal tracking-wider"
          >
            Annual Interest rate (%)
          </label>
          <input
            type="number"
            id="interest_rate"
            className="text-[16px] font-offBitDotNormal tracking-widest p-1"
            value={
              inputValue.interest_rate === 0 ? "" : inputValue.interest_rate
            }
            placeholder="0"
            onChange={(e) =>
              setInputValue({
                ...inputValue,
                interest_rate: Number(e.target.value),
              })
            }
          />
          <label
            htmlFor="recurring_amount"
            className="text-[16px] font-offBitDotNormal tracking-wider"
          >
            Monthly Investment ({outputFormat})
          </label>
          <input
            type="text"
            id="recurring_amount"
            className="text-[16px] font-offBitDotNormal tracking-widest p-1"
            value={formattedInputs.monthly_recurring_amount}
            placeholder="0"
            onChange={(e) => handleNumberInput(e, "monthly_recurring_amount")}
          />
          <label htmlFor="years" className="text-[16px] font-offBitDotNormal">
            Investment Period (Years)
          </label>
          <input
            type="number"
            id="years"
            className="text-[16px] font-offBitDotNormal tracking-widest p-1"
            value={inputValue.years === 0 ? "" : inputValue.years}
            placeholder="0"
            onChange={(e) =>
              setInputValue({ ...inputValue, years: Number(e.target.value) })
            }
          />
        </div>
        <br />
        <div
          id="calc_res"
          className="flex gap-1 flex-col self-center align-middle justify-center place-self-center w-[80vw] md:w-[40vw]"
        >
          <span
            className="text-[16px] font-offBitDotBold cursor-pointer hover:text-emerald-600"
            onClick={() =>
              copyToClipboard(res.total_invested, "Total invested")
            }
            title="Click to copy!"
          >
            Total Invested: {outputFormat}
            {!isNaN(res.total_invested)
              ? outputFormat === "$"
                ? res.total_invested.toLocaleString()
                : formatIndianNumber(res.total_invested)
              : 0}
          </span>
          <span
            className="text-[16px] font-offBitDotBold cursor-pointer hover:text-emerald-600"
            onClick={() =>
              copyToClipboard(res.total_interest, "Total interest")
            }
            title="Click to copy!"
          >
            Total Interest: {outputFormat}
            {!isNaN(res.total_interest)
              ? outputFormat === "$"
                ? res.total_interest.toLocaleString()
                : formatIndianNumber(res.total_interest)
              : 0}
          </span>
          <span className="flex items-center gap-2">
            <span
              className="text-[16px] font-offBitDotBold cursor-pointer hover:text-emerald-600"
              onClick={() => copyToClipboard(res.total_amount, "Total amount")}
              title="Click to copy!"
            >
              <span className="text-[16px] font-offBitDotBold underline">
                Total Amount:
              </span>
              <span className="text-emerald-600 text-[16px] font-offBitDotBold">
                {" " +
                  outputFormat +
                  (!isNaN(res.total_amount)
                    ? outputFormat === "$"
                      ? res.total_amount.toLocaleString()
                      : formatIndianNumber(res.total_amount)
                    : 0)}
              </span>
            </span>
          </span>
          <div id="graph" className="w-full max-w-md mx-auto mt-4">
            <Doughnut data={chartData} options={chartOptions} />
          </div>
          {copyMessage && (
            <span className="text-emerald-600 text-sm font-offBitDotNormal mt-2 absolute bottom-[40px]">
              {copyMessage}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default Calc;
