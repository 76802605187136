import React, { useRef } from "react";
import gsap from "gsap";

function MenuItem(props) {
  const menuItemRef = useRef(null);
  const textRef = useRef(null);
  const handleMouseOver = () => {
    if (props.mousePos.prevY > props.mousePos.y) {
      console.log("up");
      gsap.to(textRef.current, {
        scaleY: 1,
        transformOrigin: "bottom",
        duration: 0.2,
        ease: "power1.out",
      });
    } else {
      console.log("down");
      gsap.to(textRef.current, {
        scaleY: 1,
        transformOrigin: "up",
        duration: 0.2,
        ease: "power1.out",
      });
    }
  };

  const handleMouseLeave = () => {
    if (props.mousePos.prevY > props.mousePos.y) {
      gsap.to(textRef.current, {
        scaleY: 0,
        transformOrigin: "top",
        duration: 0.2,
        ease: "power1.out",
      });
    } else {
      gsap.to(textRef.current, {
        scaleY: 0,
        transformOrigin: "bottom",
        duration: 0.2,
        ease: "power1.out",
      });
    }
  };

  return (
    <div
      className="w-full h-[10https://mega.nz/folder/NokGXIoI#ZJBvzLc7996aEZ4J_m0uSg/file/VgVEFTYLvw] font-YapariVar specific-div relative border-y-[1.2px] border-[#808080] select-none"
      ref={menuItemRef}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center overflow-hidden marquee">
        <h1
          className="text-white tracking-[.5vw] text-center text-[7.5vw] leading-[.9] pt-4 align-sub m-0 p-0 w-full whitespace-nowrap"
          style={{
            fontVariationSettings: "'wght' 650 , 'wdth' 100",
          }}
        >
          {props.name}
        </h1>
      </div>

      <div
        className="absolute top-0 left-0 w-full h-full flex justify-center items-center overflow-hidden marquee bg-[#c6fc23] scale-y-0"
        style={{ zIndex: 1 }}
        ref={textRef}
      >
        <h1
          className="text-black tracking-[8px] text-[7vw] leading-[.9] pt-[2vw] align-sub m-0 p-0 w-full h-full whitespace-nowrap"
          style={{
            fontVariationSettings: "'wght' 200 , 'wdth' 100",
            animation: "marquee 5s linear infinite",
          }}
          ref={textRef}
        >
          {props.completeName}
        </h1>
      </div>
    </div>
  );
}

export default MenuItem;
