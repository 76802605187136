import { useEffect, useState } from "react";
import { Link, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);
  useEffect(() => {
    console.log(" .-. \n(o o) boo!\n| O \\\n\\   \\\n `~~~'");
  }, []);
  const [color, setColor] = useState("");

  useEffect(() => {
    const palette = [
      "#ff0000",
      "#ff8700",
      "#ffd300",
      "#deff0a",
      "#0aefff",
      "#580aff",
      "#be0aff",
    ];
    const index = Math.floor(Math.random() * palette.length);
    setColor(palette[index]);
  }, []);

  return (
    <div className="bg-black w-screen h-screen overflow-hidden scrollbar-hide">
      <div className="bg"></div>
      <p className="font-offBitDotNormal text-center flex justify-center items-center h-full text-white text-[30px]  selection:bg-[#070707] selection:text-[#fbfbff]">
        <i>
          {error.statusText === "Not Found" || error.message === "Not Found" ? (
            <>
              <p className="pb-[20px]">
                Sorry, an unexpected error has occurred.
              </p>
              <span className="font-offBitDotBold" style={{ color: color }}>
                404
              </span>{" "}
              - Page not found
            </>
          ) : (
            <>
              <p>Sorry, an unexpected error has occurred.</p>
              <i>{error.statusText || error.message}</i>
            </>
          )}
        </i>
      </p>
      <div className="sticky bottom-[30px] flex justify-between">
        <div className="px-[30px]">
          <Link
            to="/home"
            className="hover:underline text-[25px] text-white font-offBitDotNormal"
          >
            home
          </Link>
        </div>
        <div className=" px-[70px]">
          <p className="text-[14px] font-offBitDotNormal text-white tracking-widest">
            check console log for error
          </p>
        </div>
      </div>
    </div>
  );
}
