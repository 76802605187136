import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./routes/Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Clock from "./routes/Clock";
import Login from "./routes/Login";
import NewClock from "./routes/NewClock";
import Test from "./routes/Test";
import ErrorPage from "./routes/error-page";
import Monkey from "./routes/Monkey";
import NewLayout from "./routes/NewLayout";
import AnotherPage from "./routes/AnotherPage";
import Menu from "./routes/Menu";
import Notepad from "./routes/Notepad";
import CurrentlyListening from "./routes/CurrentlyListening";
import TimerPage from "./routes/TimerPage";
import CountDownPage from "./routes/CountDownPage";
import OfficeCountDown from "./routes/OfficeCountDown";
import Calc from "./routes/Calc";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/home",
    element: <App />,
  },
  {
    path: "test",
    element: <Test />,
  },
  {
    path: "clock",
    element: <Clock />,
  },
  {
    path: "newclock",
    element: <NewClock />,
  },
  {
    path: "/monkey",
    element: <Monkey />,
  },
  {
    path: "/newlayout",
    element: <NewLayout />,
  },
  {
    path: "/anotherpage",
    element: <AnotherPage />,
  },
  {
    path: "/menu",
    element: <Menu />,
  },
  {
    path: "/notepad",
    element: <Notepad />,
  },
  {
    path: "/currentlyListening",
    element: <CurrentlyListening />
  },
  {
    path: "/timefromnow",
    element: <TimerPage />
  },
  {
    path: "/countdownto/:id",
    element: <CountDownPage />
  },
  {
    path: "/officehours",
    element: <OfficeCountDown />
  },
  {
    path: "/calc",
    element: <Calc />
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
