import React from "react";
import { Link } from "react-router-dom";

function Monkey() {
  // if (isLoading) {
  //   return (
  //     <div className="bg-black w-screen h-screen relative subpixel-antialiased flex justify-center items-center">
  //       <div className="bg"></div>
  //       <div className="text-white text-[1.5vw] z-0 text-center">
  //         loading...
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="bg-black w-screen h-screen relative subpixel-antialiased flex justify-center items-center selection:bg-[#070707] selection:text-white">
      <div className="bg"></div>
      <div
        className="text-transparent font-whyte text-[12vw] z-0 blur-[0px]"
        style={{
          WebkitTextStrokeWidth: ".008em",
          WebkitTextStrokeColor: "#FFB515",
        }}
      >
        Ember Monkey
      </div>
      <video
        className="absolute z-0 w-[50vw] rounded-full mix-blend-screen"
        autoPlay
        loop
        muted
      >
        <source src="https://i.imgur.com/e2Q8OQM.mp4" type="video/mp4" />
      </video>
      {/* <img
        onMouseOver={() => setSrc("https://i.imgur.com/LTrWA9a.png")}
        onMouseLeave={() => setSrc("https://i.imgur.com/YpzTguO.png")}
        className="absolute z-0 w-[50vw] select-none rounded-full"
        src={src}
        style={{
          userDrag: "none",
          userSelect: "none",
          MozUserSelect: "none",
          WebkitUserDrag: "none",
          WebkitUserSelect: "none",
          msUserSelect: "none",
        }}
        alt="monkey head"
      ></img> */}
      {/* <img
        className="absolute z-0 w-[50vw] select-none rounded-full"
        src="https://i.imgur.com/CroYnB7.png"
        alt="monkey head"
      ></img> */}
      <div className="absolute z-0 bottom-[30px] text-white left-[45px] p-[5px] m-0  font-offBitDotNormal text-[25px]">
        <Link to="/home" className="hover:underline text-white blur-[0px]">
          home
        </Link>
      </div>
    </div>
  );
}

export default Monkey;
