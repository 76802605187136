import React, { useEffect, useState } from "react";
import MenuItem from "./MenuItem";

function Menu() {
  const [mousePos, setMousePos] = useState({ x: 0, y: 0, prevX: 0, prevY: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePos((prev) => {
        return {
          prevX: prev.x,
          prevY: prev.y,
          x: e.clientX,
          y: e.clientY,
        };
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const menuItems = [
    {
      name: "HOME PAGE",
      completeName:
        "HOME PAGE HOME PAGE HOME PAGE HOME PAGE HOME PAGE HOME PAGE HOME",
    },
    {
      name: "SETTINGS",
      completeName:
        "SETTINGS SETTINGS SETTINGS SETTINGS SETTINGS SETTINGS SETTINGS ",
    },
    {
      name: "INFO",
      completeName:
        "INFORMATION INFORMATION INFORMATION INFORMATION INFORMATION INFORMATION ",
    },
  ];

  return (
    <div className="w-screen h-screen bg-black pt-[200px] relative overflow-clip">
      {menuItems.map((item, index) => {
        return (
          <MenuItem
            key={index}
            name={item.name}
            completeName={item.completeName}
            mousePos={mousePos}
          />
        );
      })}
    </div>
  );
}

export default Menu;
