import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function CountDownPage() {
  const { id } = useParams();
  const [time, setTime] = useState({
    days: "",
    hours: "",
    minutes: "",
    seconds: "",
  });
  const [selected, setSelected] = useState(null);
  const [titleState, setTitleState] = useState("Countdown");
  const [showSeconds, setShowSeconds] = useState(false);

  const formatHours = (hours, mode) => {
    let res = 0;
    if (mode === "12") {
      if (hours === 0) {
        res = 12;
      } else if (hours > 12) {
        res = hours - 12;
      } else {
        res = hours;
      }
    } else {
      res = hours;
    }
    return res.toString().length === 1 ? "0" + res : res;
  };

  useEffect(() => {
    const targetDate = new Date(id);
    const intervalId = setInterval(() => {
      const now = new Date();
      const distance = targetDate.getTime() - now.getTime();

      if (distance <= 0) {
        clearInterval(intervalId);
        setTime({ days: "00", hours: "00", minutes: "00", seconds: "00" });
        setTitleState("Countdown");
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const nHours = formatHours(hours, "24");
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setTime({ days, hours: nHours, minutes, seconds });

        const formattedTime = `T - ${days > 0 ? days + "d:" : ""}${
          nHours === "00" ? "" : nHours + "h:"
        }${minutes.toString().padStart(2, "0")}m`;
        setTitleState(formattedTime);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [id]);

  const handleSpanClick = (index) => {
    setSelected((prev) => {
      return prev === index ? null : index;
    });
  };

  return (
    <div className="bg-black w-screen text-white h-screen overflow-hidden scrollbar-hide font-offBitDotBold selection:bg-[#070707] selection:text-[#fbfbff]">
      <Helmet>
        <title>{titleState}</title>
      </Helmet>
      <div className="bg"></div>
      <div className="h-full flex flex-col z-100 w-full">
        <div className="flex w-full text-[25px] justify-between">
          <div className="m-[30px] font-offBitDotNormal text-[#39afe5] pl-[10px]">
            Countdown
          </div>
          <button
            className="m-[30px] font-offBitDotNormal text-[#39afe5] pl-[10px]"
            onClick={() => {
              setShowSeconds((prev) => !prev);
            }}
          >
            Show Seconds
          </button>
        </div>

        <p className="font-offBitDotBold mt-[17.5%] text-[100px] self-center">
          {time.days > 0 && (
            <>
              <span
                onClick={() => handleSpanClick(0)}
                className={
                  selected === 0
                    ? "text-[#ceff1a] cursor-pointer"
                    : "cursor-pointer"
                }
              >
                {time.days}
              </span>{" "}
              <span className="text-[30px]">days</span>{" "}
            </>
          )}
          <span
            onClick={() => handleSpanClick(1)}
            className={
              selected === 1
                ? "text-[#ceff1a] cursor-pointer"
                : "cursor-pointer"
            }
          >
            {time.hours}
          </span>{" "}
          <span className="text-[30px]">hrs</span>{" "}
          <span
            onClick={() => handleSpanClick(2)}
            className={
              selected === 2
                ? "text-[#ceff1a] cursor-pointer"
                : "cursor-pointer"
            }
          >
            {time.minutes.toString().length === 1
              ? "0" + time.minutes
              : time.minutes}
          </span>{" "}
          <span className="text-[30px]">minutes</span>{" "}
          {showSeconds && (
            <>
              <span
                onClick={() => handleSpanClick(3)}
                className={
                  selected === 3
                    ? "text-[#ceff1a] cursor-pointer"
                    : "cursor-pointer"
                }
              >
                {time.seconds.toString().length === 1
                  ? "0" + time.seconds
                  : time.seconds}
              </span>{" "}
              <span className="text-[30px]">seconds</span>{" "}
            </>
          )}
          <span className="text-[60px] text-[#ff0000]">to go</span>
        </p>
      </div>
      <div className="sticky bottom-[30px] text-[25px] px-[50px] font-offBitDotNormal flex justify-between">
        <Link to="/home" className="hover:underline text-white ">
          home
        </Link>
        <p className="text-[#39afe5]">{id.split("T").join(" ")}</p>
      </div>
    </div>
  );
}
