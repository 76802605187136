import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Clock() {
  const [time, setTime] = useState({
    hours: "",
    minutes: "",
    seconds: "",
    ampm: "",
    month: "",
    year: "",
    day: "",
    date: "",
  });
  const [selected, setSelected] = useState(4);
  const [mode, setMode] = useState("12");

  const formatHours = (hours, mode) => {
    let res = 0;
    if (mode === "12") {
      if (hours === 0) {
        res = 12;
      } else if (hours > 12) {
        res = hours - 12;
      } else {
        res = hours;
      }
    } else {
      res = hours;
    }
    return res.toString().length === 1 ? "0" + res : res;
  };

  useEffect(() => {
    const formatTime = () => {
      const time = new Date();
      const numDay = time.getDay();
      const day =
        numDay === 0
          ? "Sunday"
          : numDay === 1
          ? "Monday"
          : numDay === 2
          ? "Tuesday"
          : numDay === 3
          ? "Wednesday"
          : numDay === 4
          ? "Thursday"
          : numDay === 5
          ? "Friday"
          : "Saturday";
      const hours = time.getHours();
      let nHours = formatHours(hours, mode);
      let em = mode === "12" ? (hours < 12 ? "AM" : "PM") : null;
      let minutes = time.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      let seconds = time.getSeconds();
      seconds = seconds < 10 ? "0" + seconds : seconds;
      const numMonth = time.getMonth();
      const month =
        numMonth === 0
          ? "January"
          : numMonth === 1
          ? "February"
          : numMonth === 2
          ? "March"
          : numMonth === 3
          ? "April"
          : numMonth === 4
          ? "May"
          : numMonth === 5
          ? "June"
          : numMonth === 6
          ? "July"
          : numMonth === 7
          ? "August"
          : numMonth === 8
          ? "September"
          : numMonth === 9
          ? "October"
          : numMonth === 10
          ? "November"
          : "December";
      const year = time.getFullYear();
      const date = time.getDate();
      return {
        hours: nHours,
        minutes: minutes,
        seconds: seconds,
        ampm: em,
        day: day,
        month: month,
        year: year,
        date: date,
      };
    };
    const id = setInterval(() => {
      const time = formatTime();
      setTime(time);
    }, 500);
    return () => {
      clearInterval(id);
    };
  }, [mode]);

  const handleSpanClick = (index) => {
    setSelected((prev) => {
      return prev === index ? null : index;
    });
  };

  const handle12 = () => {
    setMode((prev) => {
      return prev === "12" ? "24" : "12";
    });
  };

  return (
    <div className="bg-black w-screen text-white h-screen overflow-hidden scrollbar-hide font-offBitDotBold selection:bg-[#070707] selection:text-[#fbfbff]">
      <div className="bg"></div>
      <div className="h-full flex flex-col z-100 w-full">
        <div className="flex w-full text-[25px] justify-between">
          <div className="m-[30px] font-offBitDotNormal text-[#39afe5] pl-[10px]">
            {time.day.toLowerCase()}
          </div>
          <div
            className="font-offBitDotNormal m-[30px] cursor-pointer hover:underline pr-[10px]"
            onClick={handle12}
          >
            12 / 24
          </div>
        </div>
        <p className="font-offBitDotBold mt-[17.5%] text-[100px] self-center">
          <span
            onClick={() => handleSpanClick(1)}
            className={
              selected === 1
                ? "text-[#ceff1a] cursor-pointer"
                : "cursor-pointer"
            }
          >
            {time.hours}
          </span>{" "}
          :{" "}
          <span
            onClick={() => handleSpanClick(2)}
            className={
              selected === 2
                ? "text-[#ceff1a] cursor-pointer"
                : "cursor-pointer"
            }
          >
            {time.minutes}
          </span>{" "}
          :{" "}
          <span
            onClick={() => handleSpanClick(3)}
            className={
              selected === 3
                ? "text-[#ceff1a] cursor-pointer"
                : "cursor-pointer"
            }
          >
            {time.seconds}
          </span>{" "}
          <span
            onClick={() => handleSpanClick(4)}
            className={
              selected === 4
                ? "text-[#ceff1a] cursor-pointer"
                : "cursor-pointer"
            }
          >
            {time.ampm}
          </span>
        </p>
      </div>
      <div className="sticky bottom-[30px] text-[25px] px-[50px] font-offBitDotNormal flex justify-between">
        <Link to="/home" className="hover:underline text-white ">
          home
        </Link>
        <p className="text-[#39afe5]">{`${time.date} ${time.month}, ${time.year}`}</p>
      </div>
    </div>
  );
}

export default Clock;
