import React, { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import darkmode from "../assets/darkmode.svg";
import lightmode from "../assets/lightmode.svg";
import show from "../assets/show.svg";
import hide from "../assets/hidden.svg";
import showlight from "../assets/showlight.svg";
import hidelight from "../assets/hiddenlight.svg";
import { gsap } from "gsap";

function Notepad() {
  const [details, setDetails] = useState({ username: "", password: "" });
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [notepadData, setNotepadData] = useState({
    username: "",
    data: "",
  });
  const messageRef = useRef(null);
  const [view, setView] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [darkMode, setDarkMode] = useState(() => {
    if (Cookies.get("darkMode") === "true") {
      return true;
    } else if (Cookies.get("darkMode") === "false") {
      return false;
    } else {
      Cookies.set("darkMode", false);
      return false;
    }
  });
  const [saveMessage, setSaveMessage] = useState("/ctrl+s");
  const saveRef = useRef(null);

  const handleMouseOver = () => {
    setIsMouseOver(true);
  };

  const handleMouseOut = () => {
    setIsMouseOver(false);
  };

  const textStyle = {
    WebkitTextStrokeWidth: isMouseOver ? ".05em" : "0",
    WebkitTextStrokeColor: isMouseOver
      ? darkMode
        ? "#dddddd"
        : "#121212"
      : "transparent",
    color: isMouseOver ? "transparent" : darkMode ? "#dddddd" : "#121212",
    zIndex: 1,
  };

  //fetchdata function
  const getNotePadData = () => {
    if (localStorage.getItem("myObject")) {
      setNotepadData(JSON.parse(localStorage.getItem("myObject")));
      return;
    } else {
      fetch("https://testbackend-cql1.onrender.com/getnotepadData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: details.username,
          password: details.password,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setNotepadData(data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  //save button
  const save = () => {
    gsap.to(saveRef.current, {
      duration: 0.1,
      scale: 0.95,
      ease: "power1.inOut",
      onComplete: () => {
        gsap.to(saveRef.current, {
          delay: 0.1,
          duration: 0.1,
          scale: 1,
          ease: "back",
        });
      },
    });
    if (localStorage.getItem("myObject")) {
      localStorage.setItem("myObject", JSON.stringify(notepadData));
      setSaveMessage("//Savedd");
      setTimeout(() => {
        setSaveMessage("");
      }, 1000);
      return;
    } else {
      fetch("https://testbackend-cql1.onrender.com/savenotepadData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: details.username,
          password: details.password,
          data: notepadData.data,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.insertedId) {
            setSaveMessage("//Savedd");
            setTimeout(() => {
              setSaveMessage("");
            }, 1000);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.ctrlKey && event.key === "s") {
        event.preventDefault();
        gsap.to(saveRef.current, {
          duration: 0.1,
          scale: 0.95,
          ease: "power1.inOut",
          onComplete: () => {
            gsap.to(saveRef.current, {
              delay: 0.1,
              duration: 0.1,
              scale: 1,
              ease: "back",
            });
          },
        });
        if (localStorage.getItem("myObject")) {
          localStorage.setItem("myObject", JSON.stringify(notepadData));
          setSaveMessage("//Savedd");
          setTimeout(() => {
            setSaveMessage("");
          }, 1000);
          return;
        } else {
          fetch("https://testbackend-cql1.onrender.com/savenotepadData", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: details.username,
              password: details.password,
              data: notepadData.data,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.insertedId) {
                setSaveMessage("//Savedd");
                setTimeout(() => {
                  setSaveMessage("");
                }, 1000);
              }
            })
            .catch((error) => {
              console.error(error);
            });
          console.log("Ctrl+S pressed");
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [details, notepadData]);

  useEffect(() => {
    if (Cookies.get("isLoggedIn") === "true") {
      setIsLoggedIn(true);
      if (localStorage.getItem("myObject")) {
        const fetchedUsername = Cookies.get("username");
        const fetchedPassword = Cookies.get("password");
        setDetails({ username: fetchedUsername, password: fetchedPassword });
        setNotepadData(JSON.parse(localStorage.getItem("myObject")));
        return;
      } else {
        const fetchedUsername = Cookies.get("username");
        const fetchedPassword = Cookies.get("password");
        setDetails({ username: fetchedUsername, password: fetchedPassword });
        fetch("https://testbackend-cql1.onrender.com/getnotepadData", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: fetchedUsername,
            password: fetchedPassword,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              setNotepadData(data);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  //login
  const handleSubmit = (e) => {
    e.preventDefault();
    if (details.username === "user" && details.password === "pass") {
      Cookies.set("isLoggedIn", true);
      Cookies.set("username", details.username);
      Cookies.set("password", details.password);
      setIsLoggedIn(true);
      setNotepadData({
        user: details.username,
        data: "",
      });
      localStorage.setItem(
        "myObject",
        JSON.stringify({
          user: details.username,
          data: "",
        })
      );
      return;
    } else {
      fetch("https://testbackend-cql1.onrender.com/notepadlogin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: details.username,
          password: details.password,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.success) {
            Cookies.set("isLoggedIn", true);
            Cookies.set("username", details.username);
            Cookies.set("password", details.password);
            setIsLoggedIn(true);
            getNotePadData();
          } else {
            messageRef.current.innerHTML = data.message;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  //update state on change
  const handleChange = (event) => {
    setNotepadData((prev) => ({ ...prev, data: event.target.value }));
  };

  //autosave abolished

  const handleLogout = () => {
    if (localStorage.getItem("myObject")) {
      localStorage.removeItem("myObject");
    }
    Cookies.set("isLoggedIn", false);
    Cookies.set("username", "");
    setIsLoggedIn(false);
    setDetails({ username: "", password: "" });
    setNotepadData({
      username: "",
      data: "",
    });
    setIsMouseOver(false);
  };

  if (!isLoggedIn) {
    return (
      <div
        className={
          darkMode
            ? "w-screen h-screen flex flex-col justify-start items-center bg-[#0b0b0b] text-[#dddddd]"
            : "w-screen h-screen flex flex-col justify-start items-center bg-[#ffffff] text-[#000000]"
        }
        style={{ zIndex: -1 }}
      >
        <div
          className={
            darkMode
              ? "fixed rounded-[10px] border-[#4b4b4b] border-4 border-double w-[98%] h-[96%] top-[2vh] bg-transparent"
              : "fixed rounded-[10px] border-[#121212] border-4 border-double w-[98%] h-[96%] top-[2vh] bg-transparent"
          }
          style={{ zIndex: 0 }}
        ></div>
        <div
          className="select-none font-Megazoid text-[5vmin] pt-[10rem] whitespace-nowrap tracking-wider"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          style={textStyle}
        >
          welcome to notepad!
        </div>
        <form
          className="w-[90%] sm:w-[70%] md:w-[50%] lg:w-[30%] xl:w-[25%] h-max p-6 flex flex-col justify-start items-center font-mono"
          onSubmit={handleSubmit}
          style={{ zIndex: 1 }}
        >
          <label
            className="w-[150%] text-center p-4 pt-2"
            style={{ zIndex: 1 }}
          >
            test credentials: user/pass -{" "}
            <span className="underline">uses LocalStorage</span>
          </label>
          <label
            className="w-full p-4 text-red-600"
            ref={messageRef}
            style={{ zIndex: 1 }}
          ></label>
          <label className="w-full p-4" style={{ zIndex: 1 }}>
            Username{" "}
            <input
              type="text"
              style={{ zIndex: 1 }}
              className={
                darkMode
                  ? "rounded-[10px] border-[#4b4b4b] border-4 border-double h-[40px] w-full p-3 bg-[#0b0b0b]"
                  : "rounded-[10px] border-[#121212] border-4 border-double h-[40px] w-full p-3 bg-[#ffffff]"
              }
              value={details.username}
              onChange={(e) =>
                setDetails({
                  ...details,
                  username: e.target.value.toLowerCase().trim(),
                })
              }
            />
          </label>
          <label className="w-full p-4" style={{ zIndex: 1 }}>
            Password{" "}
            <div className="w-full h-full  relative" style={{ zIndex: 1 }}>
              <img
                style={{ zIndex: 1 }}
                src={
                  view
                    ? darkMode
                      ? showlight
                      : show
                    : darkMode
                    ? hidelight
                    : hide
                }
                onClick={() => setView(!view)}
                alt="view/hide password"
                className={
                  view
                    ? "select-none w-[1.6em] absolute right-0 p-[.2em] mt-[.6205rem] mx-[.6rem] cursor-pointer scale-90"
                    : "select-none w-[1.6em] absolute right-0 p-[.2em] my-2 mx-[.6rem] cursor-pointer  scale-90"
                }
              />
              <input
                style={{ zIndex: 1 }}
                type={view ? "text" : "password"}
                className={
                  darkMode
                    ? "rounded-[10px] border-[#4b4b4b] border-4 border-double h-[40px] w-full p-3 bg-[#0b0b0b]"
                    : "rounded-[10px] border-[#121212] border-4 border-double h-[40px] w-full p-3 bg-[#ffffff]"
                }
                value={details.password}
                onChange={(e) =>
                  setDetails({ ...details, password: e.target.value.trim() })
                }
              />
            </div>
          </label>
          <div className="w-full p-4 flex justify-center" style={{ zIndex: 1 }}>
            <button
              style={{ zIndex: 1 }}
              className={
                darkMode
                  ? "select-none w-full sm:w-[75%] mx-4 h-[40px] rounded-[10px] border-4 border-[#4b4b4b] border-double text-[#dddddd] font-bold hover:bg-[#dddddd] hover:border-[#121212] hover:text-[#121212]"
                  : "select-none w-full sm:w-[75%] mx-4 h-[40px] rounded-[10px] border-4 border-[#121212] border-double text-[#121212] font-bold hover:bg-[#121212] hover:border-[#dddddd] hover:text-[#dddddd]"
              }
            >
              Login
            </button>
          </div>
        </form>
        <div
          style={{ zIndex: 1 }}
          className="fixed bottom-[5vmin] md:bottom-[30px] md:left-[40px] left-[5vmin]"
        >
          <Link
            style={{ zIndex: 1 }}
            to="/home"
            className={
              darkMode
                ? "select-none hover:underline text-[1.5rem] md:text-[25px] text-[#dddddd] font-offBitDotNormal"
                : "select-none hover:underline text-[1.5rem] md:text-[25px] text-[#121212] font-offBitDotNormal"
            }
          >
            home
          </Link>
        </div>
      </div>
    );
  }
  return (
    <div
      className={
        darkMode
          ? "w-auto h-screen p-[10px] bg-[#121212] scrollbar-hide overflow-hidden text-[#e5e5e5] selection:bg-[#03050A] selection:text-white"
          : "w-auto h-screen p-[10px] bg-[#fdfdfd] scrollbar-hide overflow-hidden text-[#0f0f0f] "
      }
    >
      {/* <div className="bg"></div> */}
      <div className="flex justify-between items-center w-full h-[40px] md:my-[10px] lg:my-[10px] px-[1vw]">
        <div className="font-Megazoid px-[10px] sm:text-[15px] md:text-[26px] lg:text-[30px] tracking-wide">
          {details.username[0] === "b"
            ? "Notepad for us 🐞"
            : details.username === "admin"
            ? "Notepad for ADMIN 🥐"
            : details.username === "hal9000"
            ? `Notepad for HAL9000 🐒🦴`
            : `Notepad for ${details.username.toUpperCase()}`}
        </div>
        <div className="flex items-center justify-between">
          <img
            src={darkMode ? lightmode : darkmode}
            className="select-none w-[1.65em] cursor-pointer p-[.24em] mr-[.3em]"
            alt="darkmode"
            onClick={() =>
              setDarkMode((prev) => {
                Cookies.set("darkMode", !prev);
                return !prev;
              })
            }
          />
          <div
            className="select-none font-Megazoid sm:text-[15px] md:text-[26px] lg:text-[30px] px-[10px] cursor-pointer tracking-wide"
            onClick={handleLogout}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            style={textStyle}
          >
            logout
          </div>
        </div>
      </div>
      <div className="h-full w-full">
        <div className="flex flex-col justify-start items-start h-full w-full p-[5px] md:p-[10px] lg:p-[10px] m-0 ">
          <textarea
            type="text"
            value={notepadData.data}
            spellCheck="false"
            onInput={handleChange}
            className={
              darkMode
                ? "selection:text-black selection:bg-[#dddddd] font-BaseNeue sm:text-[16px] md:text-[20px] lg:text-[20px] p-[20px] rounded-3xl border-[#4b4b4b] border-[5px] w-full h-[60%] bg-[#0e0e0e] border-double drop-shadow-xl filter-none"
                : "selection:bg-black selection:text-[#dddddd] font-BaseNeue sm:text-[16px] md:text-[20px] lg:text-[20px] p-[20px] rounded-3xl border-[#121212] border-[5px] w-full h-[60%] bg-[#ffffff] border-double"
            }
          ></textarea>
          <div className="flex justify-start items-center my-[2vw] ml-[2vw]">
            <button
              className={
                darkMode
                  ? "select-none sm:text-[16px] font-BaseNeue md:text-[20px] lg:text-[20px] rounded-[10px] border-4 border-[#4b4b4b] border-double  font-bold hover:bg-[#dddddd] hover:border-[#121212] hover:text-[#121212] py-[10px] px-[30px] "
                  : "select-none sm:text-[16px] font-BaseNeue md:text-[20px] lg:text-[20px] rounded-[10px] border-4 bg-[#ffffff] border-[#121212] border-double  font-bold hover:bg-[#121212] hover:border-[#ffffff] hover:text-[#ffffff] py-[10px] px-[30px] "
              }
              onClick={save}
              ref={saveRef}
            >
              Save
            </button>
            <div className="m-4 text-green-700 font-BaseNeue tracking-widest">
              {saveMessage}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notepad;
