import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Login() {
  const [value, setValue] = React.useState("");
  const click = "click_me!";
  const [color, setColor] = React.useState("");

  useEffect(() => {
    const palette = [
      "#ff0000",
      "#ff8700",
      "#ffd300",
      "#deff0a",
      "#0aefff",
      "#580aff",
      "#be0aff",
    ];
    const index = Math.floor(Math.random() * palette.length);
    setColor(palette[index]);
  }, []);

  useEffect(() => {
    if (value.length < 400) {
      const id = setInterval(() => {
        setValue((prev) => {
          if (prev.length < 400) {
            const newValue = prev + Math.random().toString(36).slice(2, 3);
            if (prev.length < 170 || prev.length >= 170 + click.length) {
              return newValue;
            } else {
              return prev + click[prev.length - 170];
            }
          } else {
            return prev;
          }
        });
      }, Math.random() * 5);

      return () => {
        clearInterval(id);
      };
    }
  }, [value.length]);

  if (
    !window.navigator.userAgent.includes("Windows") &&
    !window.navigator.userAgent.includes("Macintosh")
  ) {
    return (
      <div className="bg-black w-screen h-screen">
        <p className="pt-[300px] font-offBitDotNormal text-center text-white text-[30px]">
          Please use a PC to view this content.
        </p>
      </div>
    );
  }

  return (
    <div className="w-screen h-screen overflow-clip bg-[#0c0c0c]">
      <div className="bg"></div>
      <div className="w-[110%] h-full flex justify-start items-start">
        <p className="m-[-20px] text-[120px] tracking-[-10px] leading-[100px] text-[#292929] font-whyte w-full h-full break-all select-none">
          {value.slice(0, 170)}
          <span className="cursor-pointer" style={{ color: color }}>
            <Link to="/home">{value.slice(170, 170 + click.length)}</Link>
          </span>
          {value.slice(170 + click.length)}
        </p>
      </div>
    </div>
  );
}

export default Login;
