import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";

function Home() {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const newRef = useRef(null);
  const bottomRef = useRef(null);
  const [isOver, setIsOver] = useState(false);
  const clocksRef = useRef(null);
  const [clocksLeft, setClocksLeft] = useState(0);
  const [joke, setJoke] = useState("");
  const divRef = useRef(0);
  const [menuState, setMenuState] = useState(false);
  const [newHover, setNewHover] = useState(false);

  useEffect(() => {
    const handleMouseMove = (event) => {
      setPosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  if (newHover) {
    gsap.to(newRef.current, {
      duration: 0.4,
      backgroundColor: "#ceff1a",
      color: "#000000",
      ease: "expo",
    });
  } else {
    gsap.to(newRef.current, {
      duration: 0.4,
      backgroundColor: "black",
      ease: "expo",
      color: "#ceff1a",
    });
  }

  if (isOver) {
    gsap.to(bottomRef.current, {
      duration: 0.5,
      opacity: 0.9,
      backgroundColor: "#04060A",
      ease: "back",
      borderColor: "#313131",
    });
  } else {
    gsap.to(bottomRef.current, {
      duration: 0.5,
      backgroundColor: "#020305",
      opacity: 0.9,
      ease: "back",
      borderColor: "#303030",
    });
  }

  async function fetchRandomJoke() {
    const response = await fetch("https://icanhazdadjoke.com/", {
      headers: { Accept: "application/json" },
    });
    const data = await response.json();
    return data.joke;
  }
  useEffect(() => {
    async function loadRandomJoke() {
      const joke = await fetchRandomJoke();
      setJoke(joke);
    }
    loadRandomJoke();
  }, []);
  useEffect(() => {
    const element = clocksRef.current;
    if (element) {
      const { left } = element.getBoundingClientRect();
      setClocksLeft(left);
    }
  }, []);

  if (
    !window.navigator.userAgent.includes("Windows") &&
    !window.navigator.userAgent.includes("Macintosh")
  ) {
    return (
      <div className="bg-black w-screen h-screen">
        <p className="pt-[300px] font-offBitDotNormal text-center text-white text-[30px]">
          Please use a PC to view this content.
        </p>
      </div>
    );
  }

  const handleClockMenu = () => {
    if (menuState) {
      setMenuState(false);
    } else {
      setMenuState(true);
    }
  };

  return (
    <>
      <div className="h-screen bg-[#000000] selection:bg-[#070707] selection:text-[#fbfbff] overflow-hidden scrollbar-hide">
        <div className="bg"></div>

        <div className="flex flex-col items-center justify-start pt-[150px] h-full bg-black m-0 p-0 relative">
          <div
            className="fixed w-[500px] h-[500px] bg-gradient-radial from-[#ff002b] from-10% to-80% via-[#00b7ff] to-[#ffffff00] opacity-20 blur-[155px]"
            style={{
              left: position.x - 250,
              top: position.y - 250,
              zIndex: 0,
            }}
          ></div>
          <h1
            className="text-[270px] font-offBitDotBold text-[#ffffff] leading-none z-100"
            style={{
              zIndex: 1,
            }}
          >
            Random Joke
          </h1>
          <p
            className="text-[#39afe5] font-offBitDotNormal text-center text-[45px] z-100 px-[80px]"
            style={{
              zIndex: 1,
            }}
          >
            {joke}
          </p>

          <button
            className="p-[10px] z-0 m-[20px] rounded-lg w-[100px] font-semibold border-2 border-[#ceff1a] bg-black text-[#ceff1a]"
            onClick={async () => {
              gsap.to(newRef.current, {
                duration: 0.1,
                scale: 0.9,
                ease: "power4.easeOut",
                onComplete: () => {
                  gsap.to(newRef.current, {
                    duration: 0.1,
                    scale: 1,
                    ease: "power4.easeOut",
                  });
                },
              });
              setJoke(await fetchRandomJoke());
            }}
            ref={newRef}
            onMouseOver={() => {
              setNewHover(true);
            }}
            onMouseLeave={() => {
              setNewHover(false);
            }}
          >
            NEW
          </button>
        </div>
        {menuState && (
          <div
            className="fixed bottom-[85px]"
            ref={divRef}
            // style={{ left: (width - 180) / 2 - 20 }}
            style={{ left: clocksLeft - 20 }}
          >
            <div className="flex items-center justify-between flex-col rounded-[10px] w-[200px] py-[10px] bg-opacity-40 h-max z-100 border-[2px] border-[#303030] backdrop-blur-[10px] bg-transparent text-[#e13728]">
              <Link
                to="/clock"
                className="hover:underline text-[25px] font-offBitDotNormal px-[15px] py-[10px] tracking-[2.2px]"
              >
                ↳ oldClock
              </Link>
              <Link
                to="/newclock"
                className="hover:underline text-[25px] font-offBitDotNormal px-[15px] py-[10px] tracking-[2.2px]"
              >
                ↳ newClock
              </Link>
              <Link
                to="/timefromnow"
                className="hover:underline text-[25px] font-offBitDotNormal px-[15px] py-[10px] tracking-[2.2px]"
              >
                ↳ timer
              </Link>
            </div>
          </div>
        )}

        <div
          ref={bottomRef}
          onMouseOver={() => setIsOver(true)}
          onMouseLeave={() => setIsOver(false)}
          style={{ backgroundColor: "#020305" }}
          className="absolute bottom-0 right-0 w-full h-[80px] border-[2px] = text-[#e13728] rounded-t-lg border-[#303030] flex items-center justify-center"
        >
          <div className="flex items-center justify-between h-full w-[85%] z-0 ">
            <Link
              className="text-[25px] font-offBitDotNormal px-[20px] py-[5px] tracking-[2.2px] hover:underline title1"
              to="/test"
            >
              ⚡ test
            </Link>
            <div
              ref={clocksRef}
              onClick={handleClockMenu}
              className={
                menuState
                  ? "hover:underline text-[25px] font-offBitDotNormal px-[20px] py-[5px] tracking-[2.2px] cursor-pointer text-[#e13728] title2"
                  : "hover:underline text-[25px] font-offBitDotNormal px-[20px] py-[5px] tracking-[2.2px] cursor-pointer text-[#e13728] title2"
              }
            >
              {menuState ? "clocks ▲" : "☞ clocks"}
            </div>
            <Link
              to="/monkey"
              className="hover:underline text-[25px] font-offBitDotNormal px-[20px] py-[5px] tracking-[2.2px] title3"
            >
              ✌ monkey
            </Link>
            <Link
              to="/newlayout"
              className="hover:underline text-[25px] font-offBitDotNormal px-[20px] py-[5px] tracking-[2.2px] title4"
            >
              ☯ animation
            </Link>
            <Link
              to="/notepad"
              className="hover:underline text-[25px] font-offBitDotNormal px-[20px] py-[5px] tracking-[2.2px] title4"
            >
              ♻ notepad
            </Link>
            <Link
              to="/currentlyListening"
              className="hover:underline text-[25px] font-offBitDotNormal px-[20px] py-[5px] tracking-[2.2px] title4"
            >
              ∞ Music
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
