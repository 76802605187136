import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { Link, useNavigate } from "react-router-dom";
import { SplitText } from "../assets/splitText";

function NewLayout() {
  const comp = useRef();
  const navigate = useNavigate();
  const handleComplete = () => {
    navigate("/anotherpage");
  };

  const slideRef = useRef(null);

  const timeline = gsap.timeline({
    paused: true,
    onComplete: handleComplete,
  });

  const tl = useRef(timeline);

  useEffect(() => {
    const initline = gsap.timeline({ paused: false });
    initline.set(slideRef.current, { clipPath: "circle(75% at 50% 50%)" });
    initline.to(slideRef.current, {
      duration: 0.3,
      clipPath: "circle(0% at 50% 50%)",
    });
  }, []);

  useEffect(() => {
    const text = new SplitText(".header");
    let ctx = gsap.context(() => {
      tl.current
        .to(
          slideRef.current,
          {
            duration: 0.4,
            clipPath: "circle(75% at 50% 50%)",
          },
          "clip+=0.1"
        )
        .to(
          text.chars,
          {
            duration: 0.4,
            scaleY: 0,
            ease: "power3.inOut",
            stagger: {
              from: "center",
              amount: 0.4,
            },
          },
          "-=.4"
        );
    }, comp);
    return () => ctx.revert();
  }, []);

  const handleClick = () => {
    tl.current.play();
  };

  return (
    <div
      className="w-screen h-screen bg-[#eDeBe8] overflow-clip scrollbar-hide"
      ref={comp}
    >
      <div className="bg"></div>
      <div className="h-full w-full grid place-content-center text-[#0da89b] font-offBitDotBold">
        <div
          className="h-full w-full bg-[#d8ea71] fixed top-0 left-0"
          ref={slideRef}
          style={{
            clipPath: "circle(0% at 50% 50%)",
          }}
        ></div>
        <div
          className="text-[10vw] lg:text-[70px] select-none tracking-widest leading-none cursor-pointer p-[5px] header"
          onClick={handleClick}
        >
          ☁ NewLayout
        </div>
      </div>
      <div className="sticky bottom-[30px] text-[25px] px-[50px] font-offBitDotNormal flex justify-between">
        <Link to="/home" className="hover:underline text-black ">
          home
        </Link>
      </div>
    </div>
  );
}

export default NewLayout;
